import React from 'react'
import { Grid, Skeleton } from '@mui/material';
import { Rating, Box } from "@mui/material";
import { IoIosArrowDown } from "react-icons/io";

import ContentReviews from './ContentReviews';
import ManageReviewsTooltip from "../../../components/Admin/helpers/ManageReviewsTooltip";

const ReviewRow = ({ item, getEmojiImage }) => {
    const ratingClass = item?.rating <= 3 ? 'red' : 'blue';
    return (
        <>
            <div className='review_date_wrapper'>
                <IoIosArrowDown />
                <span>
                    {item ? new Date(item.published_at).toLocaleDateString('en-US', {
                        month: 'short',
                        day: 'numeric',
                        year: 'numeric'
                    }) : <Skeleton sx={{ width: "40px" }} />}
                </span>
            </div>
            <Grid item xs={12} sx={{ mb: 4 }} className={`section-review-star-states ${ratingClass}`}>
                <div>
                    <div className="d-flex justify-content-between grid-wrapper">
                        <div className="d-flex align-items-center stars_wrapper">
                            {item ? <ManageReviewsTooltip arrow placement="top"
                        title={`${item?.source?.name}`}><img style={{ marginRight: "17px", maxWidth: "32px" }} src={item.source.logo ? item.source.logo : "/admin-icons/feedback-irevu.svg"} className='logo_about_icon' /></ManageReviewsTooltip> : <Skeleton style={{ marginRight: "17px" }} sx={{ width: "40px", height: "80px" }} />}
                            <div className='details_wrapper'>
                                <span className='reviews-text-head'>{item ? item.author.name : <Skeleton sx={{ width: "100px", height: '20px' }} />}</span>
                                {item ?
                                    <div className='d-flex'>
                                        <Rating icon={<img src={"/admin-images/yellow-star-about.png"} className="custom-rating-sm-ic" />}
                                            emptyIcon={<img src={"/admin-images/empty-icon.svg"} className="custom-rating-sm-ic" />}
                                            value={item.rating}
                                            readOnly
                                        />
                                        <div className='vertical_line'></div>
                                        <img src={getEmojiImage(item.rating)} width="18" alt="emoji" />
                                    </div> : <Skeleton />}
                            </div>
                        </div>
                        {/* <div className="right-wrapper">
                            <div className="buttons">
                                <button className='btn' onClick={() => setShareModal(true)}>Share Review</button>
                            </div>
                        </div> */}
                    </div>
                </div>
                <Box sx={{ width: 1 }}>
                    <div style={{ marginTop: "10px" }}>
                        {/* <Rating value={item.rating} readOnly /> */}
                    </div>
                    {item ? <ContentReviews content={item.content} /> : <Skeleton />}
                </Box>
            </Grid>
        </>
    )
}

const ReviewRowMemo = React.memo(ReviewRow)

export default ReviewRowMemo