export const subscriptionsReducer = (state = [], action) => {
    switch (action.type) {
        case 'GET_SUBSCRIPTION_DETAILS':
            return {
                ...state,
                subscriptions: action.payload
            }
        case 'CLEAR_SUBSCRIPTIONS':
            return {
                ...state,
                subscriptions: undefined
            }
        case 'EDIT_SUBSCRIPTION_CARD':
            const updatedCard = action.payload.data;
            const updatedCardId = updatedCard?.id;

            const subscription_id = action.payload.id
            const subId = action.payload.subId

            // Update cards.data array
            let updatedCardsData;
            let updatedLocationsAll;

            if (subscription_id) {
                // here for create.
                updatedCardsData = [...state?.subscriptions?.cards?.data, updatedCard]
                updatedLocationsAll = state.subscriptions.locations.all.map(location =>
                    location.subscription_id === subscription_id ? { ...location, expiration_month: updatedCard.expiration_month, expiration_year: updatedCard.expiration_year, card_id: updatedCard.id, card: updatedCard.last_four_numbers, card_type: updatedCard.type } : location
                );
            } else {
                // here for update.
                updatedCardsData = state?.subscriptions?.cards?.data?.map(card =>
                    card.id === updatedCardId ? updatedCard : card
                );

                updatedLocationsAll = state.subscriptions.locations.all.map(location =>
                    location.subscription_id === subId ? { ...location, expiration_month: updatedCard.expiration_month, expiration_year: updatedCard.expiration_year, card_id: updatedCard.id, card: updatedCard.last_four_numbers, card_type: updatedCard.type } : location
                );
            }

            return {
                ...state,
                subscriptions: {
                    ...state.subscriptions,
                    cards: {
                        ...state.subscriptions.cards,
                        data: updatedCardsData
                    },
                    locations: {
                        ...state.subscriptions.locations,
                        all: updatedLocationsAll
                    }
                }
            };
        case 'DELETE_SUBSCRIPTION_CARD':
            const cardIdToDelete = action.payload.cardId;

            // Filter out the deleted card from cards.data
            const updatedData = state.subscriptions.cards.data.filter(card => card.id !== cardIdToDelete);
            // Update locations to clear expiration_month and expiration_year for the deleted card
            const updatedDeletedLocationsAll = state.subscriptions.locations.all.map(location =>
                location.card_id === cardIdToDelete ?
                    { ...location, expiration_month: '', expiration_year: '', card: null, card_type: "" } : location
            );

            return {
                ...state,
                subscriptions: {
                    ...state.subscriptions,
                    cards: {
                        ...state.subscriptions.cards,
                        data: updatedData
                    },
                    locations: {
                        ...state.subscriptions.locations,
                        all: updatedDeletedLocationsAll
                    }
                }
            };
        default:
            return {
                ...state
            }
    }
}