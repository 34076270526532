import React from 'react';

const AboutAllPhotos = ({ setShowModal, items, companyDataSelect }) => {
    return (
        <div className='position-relative all_photos_section'>
            <button className='close_modal_btn' onClick={() => setShowModal(false)}>
                <img src="/admin-images/close-modal-about-btn.svg" alt="close" />
            </button>
            <div className='company_all_photos'>
                <div className="top_head">
                    <h3>Photos of {companyDataSelect?.name}</h3>
                </div>
                <div className="grid_images">
                    {items}
                </div>
            </div>
        </div>
    )
}

export default AboutAllPhotos
