const initialStateTrendingReviews = {
    reviews_trend: {},
    loading: true,
    errors: undefined
}
const initialStateTrendingRatings = {
    ratings_trend: {},
    loading: true,
    errors: undefined
}
const initialKeywords = {
    keywords: {},
    loading: true
}
const initialPhrases = {
    phrases: {},
    loading: true
}
const initialAnalysisSuggestions = {
    analysis_suggestions: [],
    errors: undefined
}
export const analyticsReducer = (state = [] ,action) => {
    switch(action.type){
        case 'GET_ANALYSIS':
            return{
                ...state,
                analytics: action.payload
            }
        case 'CLEAR_ANALYSIS':
            return{
                ...state,
                analytics: undefined
            }
        default:
            return{
                ...state
            }
    }
}

export const keywordDetailsReducer = (state = [] ,action) => {
    switch(action.type){
        case 'GET_KEYWORD_DETAILS':
            return{
                ...state,
                keyword: action.payload
            }
        case 'CLEAR_KEYWORD_DETAILS':
            return{
                ...state,
                keyword: undefined
            }
        default:
            return{
                ...state
            }
    }
}

export const keywordAddedReducer = (state = [] ,action) => {
    switch(action.type){
        case 'GET_ADDED_KEYWORDS':
            return{
                ...state,
                keywords: action.payload
            }
        case 'CLEAR_ADDED_KEYWORDS':
            return{
                ...state,
                keywords: undefined
            }
        default:
            return{
                ...state
            }
    }
}

export const reviewsTrendReducer = (state = initialStateTrendingReviews, action) => {
    switch(action.type){
        case "GET_REVIEWS_PREDICT":
            return{
                ...state,
                reviews_trend: action.payload,
                loading: false,
                errors: undefined
            }
        case "LOADING_PREDICT_REVIEWS":
            return{
                ...state,
                loading: true
            }
        case "ERROR_PREDICT_REVIEWS":
            return{
                ...state,
                errors: action.payload
            }
        default:
            return{
                ...state
            }
    }
}

export const ratingsTrendReducer = (state = initialStateTrendingRatings, action) => {
    switch(action.type){
        case "GET_RATINGS_PREDICT":
            return{
                ...state,
                ratings_trend: action.payload,
                loading: false
            }
        case "LOADING_PREDICT_RATING":
            return{
                ...state,
                loading: true
            }
        case "ERROR_PREDICT_RATINGS":
            return{
                ...state,
                errors: action.payload
            }
        default:
            return{
                ...state
            }
    }
}

export const getTrendingKeywords = (state = initialKeywords, action) => {
    switch(action.type){
        case "GET_TRENDING_KEYWORDS":
            return{
                ...state,
                keywords: action.payload,
                errors: "",
                loading: false
            }
        case 'LOADING_TRENDING_KEYWORDS':
            return{
                ...state,
                loading: true
            }
        case 'ERROR_TRENDING_KEYWORDS':
            return{
                ...state,
                errors: action.payload,
                loading: false
            }
        default:
            return{
                ...state
            }
    }
}

export const getTrendingPhrases = (state = initialPhrases, action) => {
    switch(action.type){
        case "GET_TRENDING_PHRASES":
            return{
                ...state,
                phrases: action.payload,
                errors: "",
                loading: false
            }
        case 'LOADING_TRENDING_PHRASES':
            return{
                ...state,
                loading: true
            }
        case 'ERROR_TRENDING_PHRASES':
            return{
                ...state,
                errors: action.payload,
                loading: false
            }
        default:
            return{
                ...state
            }
    }
}

export const getAnalysisSuggestions = (state = initialAnalysisSuggestions, action) => {
    switch(action.type){
        case "GET_ANALYSIS_SUGGESTIONS" :
            return{
                ...state,
                analysis_suggestions: action.payload,
                errors: "",
                loading: false
            }
        case "ERROR_ANALYSIS_SUGGESTIONS" :
            return{
                ...state,
                errors: action.payload,
                loading: false
            }
        case "LOADING_ANALYSIS_SUGGESTIONS" :
            return{
                ...state,
                loading: true
            }
        default:
            return{
                ...state
            }
    }
}

export const analysisDashboardReducer = (state = {}, action) => {
    switch(action.type){
        case "GET_ANALYSIS_DASHBOARD":
            return{
                ...state,
                analysis_dashboard: action.payload
            }
        case 'CLEAR_ANALYSIS':
            return{
                ...state,
                analysis_dashboard: undefined
            }
        default:
            return{
                ...state
            }
    }
}

export const getReview12Status  = (state = {}, action) => {
    switch(action.type){
        case "GET_REVIEWS_12_STATUS":
            return{
                ...state,
                reviews_12_status: action.payload.data.status
            }
        default:
            return{
                ...state
            }
    }
}

export const getReview6Status  = (state = {}, action) => {
    switch(action.type){
        case "GET_REVIEWS_6_STATUS":
            return{
                ...state,
                reviews_6_status: action.payload.data.status
            }
        default:
            return{
                ...state
            }
    }
}
export const getReview3Status  = (state = {}, action) => {
    switch(action.type){
        case "GET_REVIEWS_3_STATUS":
            return{
                ...state,
                reviews_3_status: action.payload.data.status
            }
        default:
            return{
                ...state
            }
    }
}
export const getReview1Status  = (state = {}, action) => {
    switch(action.type){
        case "GET_REVIEWS_1_STATUS":
            return{
                ...state,
                reviews_1_status: action.payload.data.status
            }
        default:
            return{
                ...state
            }
    }
}
export const getRatings12Status  = (state = {}, action) => {
    switch(action.type){
        case "GET_RATINGS_12_STATUS":
            return{
                ...state,
                ratings_12_status: action.payload.data.status
            }
        default:
            return{
                ...state
            }
    }
}

export const getRatings6Status  = (state = {}, action) => {
    switch(action.type){
        case "GET_RATINGS_6_STATUS":
            return{
                ...state,
                ratings_6_status: action.payload.data.status
            }
        default:
            return{
                ...state
            }
    }
}
export const getRatings3Status  = (state = {}, action) => {
    switch(action.type){
        case "GET_RATINGS_3_STATUS":
            return{
                ...state,
                ratings_3_status: action.payload.data.status
            }
        default:
            return{
                ...state
            }
    }
}
export const getRatings1Status  = (state = {}, action) => {
    switch(action.type){
        case "GET_RATINGS_1_STATUS":
            return{
                ...state,
                ratings_1_status: action.payload.data.status
            }
        default:
            return{
                ...state
            }
    }
}

export const getCategoryKeywords  = (state = {}, action) => {
    switch(action.type){
        case "GET_CATEGORY_KEYWORDS":
            return{
                ...state,
                category_keywords: action.payload,
                errors: "",
                loading: false
            }
        case 'ERROR_CATEGORY_KEYWORDS':
            return{
                ...state,
                errors: action.payload,
                loading: false
            }
        case 'LOADING_CATEGORY_KEYWORDS':
            return{
                ...state,
                loading: true
            }
                
        default:
            return{
                ...state
            }
    }
}