import React, { useState } from 'react';
import { CircularProgress } from "@mui/material";
import axios from 'axios';
import { baseUrl } from '../Helpers/baseUrl';
import { getLocalUser } from '../TokenControl/parts/useLocalUser';

const ShareModal = ({ setShareModal, companyDataSelect }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        to: '',
        note: ''
    });
    const [loading, setLoading] = useState(false);
    const [copied, setCopied] = useState(false);
    const [errors, setErrors] = useState({});
    const [backError, setBackError] = useState({});

    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name) newErrors.name = 'Name is required';
        if (!formData.email) {
            newErrors.email = 'Email is required';
        } else if (!validateEmail(formData.email)) {
            newErrors.email = 'Invalid email format';
        }
        if (!formData.to) {
            newErrors.to = 'Recipient email is required';
        } else if (!validateEmail(formData.to)) {
            newErrors.to = 'Invalid email format';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });

        // Remove the error for the current input field
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: ''
        }));
    };

    const shareUrl = `${window.location.origin}/company/${companyDataSelect?.landing}`;

    const shareOnFacebook = () => {
        const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
        window.open(facebookUrl, '_blank');
    };

    const shareOnX = () => {
        const xUrl = `https://twitter.com/share?url=${encodeURIComponent(shareUrl)}`;
        window.open(xUrl, '_blank');
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Form submission logic here
            setLoading(true);

            axios.post(`https://api.irevu.com/api/v1/company/share`, { company_id: companyDataSelect?.id, name: formData?.name, email: formData?.email, to: formData?.to, note: formData?.note }, { headers: { 'Authorization': `Bearer ${getLocalUser().token}`, 'APP-VERSION': 'react' } })
                .then((res) => {
                    setBackError({});
                    setShareModal(false);
                }).catch(err => {
                    console.log(err);
                    setBackError(err.response.data.errors);
                }).finally(() => {
                    setLoading(false);
                })
        }
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(shareUrl).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Hide notification after 2 seconds
        });
    };

    return (
        <div className='share_modal_wrapper'>
            <div className="top__header">
                <h4>Share business</h4>
                <button className='btn' onClick={() => setShareModal(false)}>
                    <svg width="24" height="24" class="icon_svg"><path d="m13.41 12 5.3-5.29a1.004 1.004 0 1 0-1.42-1.42L12 10.59l-5.29-5.3a1.004 1.004 0 0 0-1.42 1.42l5.3 5.29-5.3 5.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l5.29-5.3 5.29 5.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42L13.41 12Z"></path></svg>
                </button>
            </div>
            <div className="share_modal_content">
                <div className="social_media_wrapper">
                    <button className='btn facebook_btn' onClick={shareOnFacebook}>
                        <svg width="24" height="24" class="fb_svg"><path d="M22 12.06a10 10 0 1 0-11.563 9.88v-6.99H7.899v-2.89h2.54V9.858a3.53 3.53 0 0 1 3.777-3.89c.75.01 1.498.075 2.238.194v2.461h-1.26a1.446 1.446 0 0 0-1.63 1.563v1.875h2.773l-.443 2.89h-2.33v6.988A10.003 10.003 0 0 0 22 12.061Z"></path></svg>
                        Share on Facebook
                    </button>
                    <button className='btn twitter_btn' onClick={shareOnX}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0.0469875 0.636719L7.76976 10.9606L0 19.358H1.74862L8.55177 12.0078L14.0493 19.358H20L11.8443 8.45347L19.077 0.636719H17.3284L11.0623 7.40631L6.00101 0.636719H0.0469875ZM2.62125 1.92553H5.35325L17.4257 18.0692H14.6904L2.62125 1.92553Z" fill="white" />
                        </svg>
                        Share on X
                    </button>
                </div>
                <div>
                    <div className='address_wrapper'>
                        <svg width="16" height="16" onClick={copyToClipboard} style={{ cursor: 'pointer' }} class="icon_svg"><path d="M14.5 1h-3.974a.5.5 0 0 0-.353.854l1.47 1.469L8.254 6.71a.75.75 0 1 0 1.06 1.06l3.388-3.386 1.444 1.443A.5.5 0 0 0 15 5.474V1.5a.5.5 0 0 0-.5-.5Z"></path><path d="M14 14.75H2a.75.75 0 0 1-.75-.75V2A.75.75 0 0 1 2 1.25h3.824a.75.75 0 0 1 0 1.5H2.75v10.5h10.5v-3.133a.75.75 0 1 1 1.5 0V14a.75.75 0 0 1-.75.75Z"></path></svg>
                        <input type="text" className='form-control address_input' value={shareUrl} />
                    </div>
                    {copied && <div className="notification__link">Link has been copied to clipboard!</div>}
                </div>

                <div className="hr_line_wrapper">
                    <fieldset>
                        <legend className="legend_wrap">
                            <span className="">OR</span>
                        </legend>
                    </fieldset>
                </div>

                <form onSubmit={handleSubmit}>
                    {backError.company_id && (
                        <div className="error-message text-center">
                            {backError.company_id[0]}
                        </div>
                    )}
                    <div className="form-group">
                        <input
                            name="name"
                            type="text"
                            className='form-control'
                            placeholder='Your Name'
                            value={formData.name}
                            onChange={handleInputChange}
                        />
                        {errors.name && <span className="error_msg">{errors.name}</span>}
                    </div>
                    <div className="form-group">
                        <input
                            name="email"
                            type="text"
                            className='form-control'
                            placeholder='Your Email'
                            value={formData.email}
                            onChange={handleInputChange}
                        />
                        {errors.email && <span className="error_msg">{errors.email}</span>}
                    </div>
                    <div className="form-group">
                        <input
                            name="to"
                            type="text"
                            className='form-control'
                            placeholder='To'
                            value={formData.to}
                            onChange={handleInputChange}
                        />
                        {errors.to && <span className="error_msg">{errors.to}</span>}
                    </div>
                    <div className="form-group">
                        <textarea name="note" className='form-control' placeholder='Add a note (optional)' value={formData.note} onChange={handleInputChange}></textarea>
                    </div>

                    <div className='button_wrapper'>
                        <button className="btn" disabled={loading}>
                            {loading ? (
                                <CircularProgress style={{ marginRight: 10, color: "#fff", width: 12, height: 12 }} />
                            ) : (
                                <>
                                    Share
                                </>
                            )}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ShareModal
