import { CircularProgress } from "@mui/material";
import React from "react";

const ConfirmModal = ({ closeConfirmModal, confirmRequestHandler, title, subTitle, loading }) => {
    return (
        <div className="wrapper-loc-edit-cart subscription-edit-card close_subscription_modal">
            <div className="cart-details-container">
                <div className="cart-header-wrapper">
                    <div onClick={closeConfirmModal} style={{ cursor: 'pointer' }}>
                        <img src="/admin-icons/arrow-left-icon-modal.svg" alt="arrow" />
                    </div>
                    <img src="/admin-icons/kore-logo.svg" alt="Kore" />
                    <div></div>
                </div>

                <div className="content-modal-close">
                    <h3>{title}</h3>
                    <p>{subTitle}</p>
                </div>
                <div className="card-details-footer">
                    <button className="cancel-btn" onClick={closeConfirmModal}>No, Cancel</button>
                    <button className="confirm-btn" onClick={confirmRequestHandler}>
                        {loading ? (
                            <CircularProgress style={{ marginRight: 10, color: "#fff", width: 12, height: 12 }} />
                        ) : (
                            <>
                                Yes, Confirm
                            </>
                        )}
                    </button>
                </div>
            </div>
        </div>
    )
}
export default ConfirmModal;