import React from "react"
import { Tooltip, tooltipClasses } from "@mui/material";
import { styled } from '@mui/material/styles';

const ManageReviewsTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#00B2E3',
        fontSize: '0.8em',
        lineHeight: "25px",
        padding: "7px 20px",
        fontFamily: "Poppins-Medium",
        color: '#fff',
        borderRadius: "100px"
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: '#00B2E3',
    },
}));

export default ManageReviewsTooltip