export const getReviewGenerateCoupons = (state = [], action) => {
    switch(action.type){
        case 'GET_GENERATED_COUPONS':
            return{
                ...state,
                generated_coupons: action.payload.data,
                loading: false
            }
        case 'LOADING_TABLES':
            return{
                ...state,
                loading: true
            }
        case 'CLEAR_GENERATED_COUPONS':
            return{
                ...state,
                loading: false,
            }
        case 'ADD_GENERATED_COUPON':
            return{
                ...state,
                generated_coupons: action.payload
            }
        case 'ASSIGN_COUPONS':
            return{
                ...state,
                generated_coupons: action.payload
            }
        case 'EDIT_GENERATED_COUPON':
            const updatedCoupons = state.generated_coupons.map(coupon =>
                coupon.id == action.payload.id ? { ...coupon, ...action.payload } : coupon
            );
            return {
                ...state,
                generated_coupons: updatedCoupons
            };
        case 'DELETE_GENERATED_COUPON':
            return{
                ...state,
                generated_coupons: state.generated_coupons.filter(coupon => !action.payload.includes(coupon.id))
            }
        default:
            return{
                ...state
            }
    }
}