import React from "react"
import { Navigate } from "react-router-dom";
import { getLocalUser } from "../TokenControl/parts/useLocalUser";
import { useSelector } from "react-redux";
import Canceled from "../Admin/Canceled";
import ALaCarteLimit from "../Admin/ALaCarteLimit";
import { useParams } from 'react-router-dom';
import NoPermission from "../Admin/noPermission";

const disableItemsAlaCarte = ["Competitor Analysis", "Listing", "Reputation", "Forms"]
const disableALaCarteCheckbox = ["Autopilot Settings", "Autopilot AI", "Facebook", "Google"]
const disableIfAllLocationAlaCarte = ["Social Media Automation"]


const chekPermissions = (userPermissions, routePermission) => {
    for(const key in userPermissions){
        if(typeof userPermissions[key] === 'object'){
            if(userPermissions[key][routePermission] !== undefined){
                return userPermissions[key][routePermission];
            } else {
                const nestedResult = chekPermissions(userPermissions[key], routePermission);
                if (nestedResult !== undefined) {
                    return nestedResult;
                }
            }
        } else if (key === routePermission){
            return userPermissions[key]
        }
    }
}

const ProtectedRoute = ({
    redirectPath = "/login",
    cancel,
    title,
    permissions,
    children
}) => {
    const { id } = useParams();
    const user = useSelector(state => state.getUserInfo.user)
    const location = useSelector(state => state.locationSelected.location)
    const user_permissions = useSelector(state => state.getUserPermissions.permissions)
    let location_details = user && user.user.locations.filter(loc => loc.id == location)

    const hasPermissions = chekPermissions(user_permissions?.data, permissions);

    if(!getLocalUser()?.token){
        return <Navigate to={redirectPath} replace />;
    }
 
    if(getLocalUser()?.token && hasPermissions == false){
        return <NoPermission/>;
    }
    
    if(location_details && location_details[0]?.is_demo == 0 && 
        location_details && location_details[0]?.acc_status == 0 && cancel){
        return <Canceled/>
    }

    if(user && 
        user.user?.locations.filter(loc => loc.plan != 0).every(loc => loc.plan == 4) && 
        disableIfAllLocationAlaCarte.includes(title)
    ){return <ALaCarteLimit/>} 
    

    if(location_details && location_details[0]?.plan == 4 && disableItemsAlaCarte.includes(title)){
        return <ALaCarteLimit/>
    }

    else if(location_details && 
       location_details[0]?.plan == 4 &&
       location_details[0]?.la_carte && 
       !JSON.parse(location_details[0].la_carte).includes("6") && 
       disableALaCarteCheckbox.includes(title)
    ){return <ALaCarteLimit/>} 
    
    else if (location_details && 
        location_details[0]?.plan == 4 && 
        title == "Connection Settings" && 
        id == "facebook" &&
        location_details[0]?.la_carte && 
        !JSON.parse(location_details[0].la_carte).includes("2")
    ){return <ALaCarteLimit/>}
    
    else if (location_details && 
        location_details[0]?.plan == 4 && 
        title == "Connection Settings" && 
        id == "google" &&
        location_details[0]?.la_carte && 
        !JSON.parse(location_details[0].la_carte).includes("1")
    ){return <ALaCarteLimit/>}

    return children
}

export default ProtectedRoute