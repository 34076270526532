import React from "react";
import { useTranslation } from "react-i18next";

const IreviewFooter = () => {
    const {t} = useTranslation()
    const style = {
        fontFamily: "Poppins-Light",
        fontSize: "14px",
        textAlign: "center",
        opacity: "0.8",
        paddingTop: "58px",
        lineHeight: "1.8",
        width: "80%",
        margin: "auto"
    }
    return(
        <div style={style}>
            <p>{t("brand")}</p>
        </div>
    )
}
export default IreviewFooter