const initialProgress = {
    dashboard_progress_info: undefined,
    loading: false
}

export const getDashboardStats = (state = [], action) => {
    switch (action.type) {
        case 'GET_DASHBOARD_STATS':
            return {
                ...state,
                dashboard: action.payload
            }
        case 'CLEAR_DASHBOARD_STATS':
            return {
                ...state,
                dashboard: undefined
            }
        case 'LOADING_DASHBOARD':
            return {
                ...state,
                loading: action.payload
            }
        default:
            return {
                ...state
            }
    }
}
export const getDashboardStatsVisitsReducer = (state = [], action) => {
    switch (action.type) {
        case 'GET_DASHBOARD_STATS_VISITS':
            return {
                ...state,
                dashboard_visits: action.payload
            }
        case 'CLEAR_DASHBOARD_VISITS':
            return {
                ...state,
                dashboard_visits: undefined
            }
        default:
            return {
                ...state
            }
    }
}
export const getDashboardProgressInfoReducer = (state = initialProgress, action) => {
    switch (action.type) {
        case 'GET_DASHBOARD_PROGRESS_INFO':
            return {
                ...state,
                dashboard_progress_info: action.payload,
                loading: false
            }
        case 'CLEAR_DASHBOARD_PROGRESS_INFO':
            return {
                ...state,
                dashboard_progress_info: undefined,
                loading: false
            }
        case 'LOADING_DASHBOARD_PROGRESS_INFO':
            return{
                ...state,
                loading: true
            }
        default:
            return {
                ...state
            }
    }
}
export const getDashboardChartsReducer = (state = [], action) => {
    switch (action.type) {
        case 'GET_DASHBOARD_CHARTS':
            return {
                ...state,
                dashboard_charts: action.payload
            }
        case 'CLEAR_DASHBOARD_CHARTS':
            return {
                ...state,
                dashboard_charts: undefined
            }
        default:
            return {
                ...state
            }
    }
}

export const getReviewCollectionDashboard = (state = {}, action) => {
    switch (action.type) {
        case 'GET_DASHBOARD_REVIEW_COLLECTION':
            return {
                ...state,
                review_collection: action.payload
            }
        case 'CLEAR_DASHBOARD_REVIEW_COLLECTION':
            return {
                ...state,
                review_collection: undefined
            }
        default:
            return {
                ...state
            }
    }
}