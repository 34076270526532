import React from "react";

const CloseEditCard = ({ closeConfirmModal, cancelConfirmModal }) => {
    return (
        <div className="wrapper-loc-edit-cart subscription-edit-card close_subscription_modal">
            <div className="cart-details-container">
                <div className="cart-header-wrapper">
                    <div onClick={cancelConfirmModal} style={{ cursor: 'pointer' }}>
                        <img src="/admin-icons/arrow-left-icon-modal.svg" alt="arrow" />
                    </div>
                    <img src="/admin-icons/kore-logo.svg" alt="Kore" />
                    <div></div>
                </div>

                <div className="content-modal-close">
                    <h3>Confirm Close</h3>
                    <p>Are you sure you want to close this form?</p>
                </div>
                <div className="card-details-footer">
                    <button className="cancel-btn" onClick={cancelConfirmModal}>No, Cancel</button>
                    <button className="confirm-btn" onClick={closeConfirmModal}>Yes, Confirm</button>
                </div>
            </div>
        </div>
    )
}
export default CloseEditCard;