import axios from 'axios';
import { baseUrlDev } from './baseUrl';
import { getLocalUser } from '../TokenControl/parts/useLocalUser';

class AppUrl {
    static getData(url, params = {}, additionalHeaders = {}, onSuccess = () => {}, onFailure = () => {}) {
        const defaultHeaders = {
            'Authorization': `Bearer ${getLocalUser().token}`, 
            'APP-VERSION': 'react' 
        }
        const headers = { ...defaultHeaders, ...additionalHeaders };
        axios.get(`${baseUrlDev}${url}`, { params }, {headers})
            .then(response => onSuccess(response.data))
            .catch(error => onFailure(error));
    }

    static postData(url, data = {}, additionalHeaders = {}, onSuccess = () => {}, onFailure = () => {}) {
        const defaultHeaders = {
            'Authorization': `Bearer ${getLocalUser().token}`, 
            'APP-VERSION': 'react' 
        }
        const headers = { ...defaultHeaders, ...additionalHeaders };
        axios.post(`${baseUrlDev}${url}`, data, {headers})
            .then(response => onSuccess(response.data))
            .catch(error => onFailure(error));
    }

    static putData(url, data = {}, additionalHeaders = {}, onSuccess = () => {}, onFailure = () => {}) {
        const defaultHeaders = {
            'Authorization': `Bearer ${getLocalUser().token}`, 
            'APP-VERSION': 'react' 
        }
        const headers = { ...defaultHeaders, ...additionalHeaders };
        axios.put(`${baseUrlDev}${url}`, data, {headers})
            .then(response => onSuccess(response.data))
            .catch(error => onFailure(error));
    }

    static deleteData(url, data = {}, additionalHeaders = {}, onSuccess = () => {}, onFailure = () => {}) {
        const defaultHeaders = {
            'Authorization': `Bearer ${getLocalUser().token}`, 
            'APP-VERSION': 'react' 
        }
        const headers = { ...defaultHeaders, ...additionalHeaders };
        axios.delete(`${baseUrlDev}${url}`,{headers, data})
            .then(response => onSuccess(response.data))
            .catch(error => onFailure(error));
    }
}

export default AppUrl;