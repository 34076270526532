import React, { useState } from 'react';
import { AiFillDelete } from "react-icons/ai";
import { CircularProgress } from "@mui/material";

const AddPhotoModal = ({ setShowAddModal, setShowModal }) => {
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleFiles = (files) => {
        const newImages = Array.from(files).map(file => URL.createObjectURL(file));
        setImages(prevImages => [...prevImages, ...newImages]);
    };

    const handleFileInputChange = (event) => {
        handleFiles(event.target.files);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        handleFiles(event.dataTransfer.files);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDelete = (image) => {
        setImages(images.filter(img => img !== image));
    };

    const handleSave = () => {
        setLoading(true);
        // Implement the axios request to save images
        console.log('Saving images', images);
        setTimeout(() => {
            setLoading(false);
        }, 5000);
    };

    return (
        <div className='position-relative add_photos_section'>
            <button className='close_modal_btn' onClick={() => setShowAddModal(false)}>
                <img src="/admin-images/close-modal-about-btn.svg" alt="close" />
            </button>
            <div className='add_photos_modal'>
                <div className="top_head">
                    <h3>
                        <span>The Social Plus: </span>
                        Add Photos
                    </h3>
                    <button onClick={() => { setShowAddModal(false); setShowModal(true); }}>
                        <img src="/admin-images/arrow-turn-backward.svg" alt="icon" />
                        <span>View all Photos</span>
                    </button>
                </div>
                <div className="add_photos_content">
                    <div className="wrapper" onDrop={handleDrop} onDragOver={handleDragOver}>
                        {
                            images?.length === 0 && <div className="image">
                                <img src="/admin-images/upload_image_re.svg" className='w-100' alt="icon" />
                            </div>
                        }

                        <div className="uploaded-images">
                            {images?.map((image, index) => (
                                <div className="box" key={index}>
                                    <button className='btn delete_img_btn' onClick={() => handleDelete(image)}>
                                        <img src="/admin-icons/trash-red-icon.svg" alt="remove icon" />
                                    </button>
                                    <img src={image} className='uploaded_img' alt={`uploaded ${index}`} />
                                </div>
                            ))}
                        </div>
                        {images.length === 0 && <h4>Drag and drop photos here or</h4>}
                        <div>
                            {images.length === 0 ? (
                                <button className='btn'>
                                    <input type="file" onChange={handleFileInputChange} multiple />
                                    Upload here
                                </button>
                            ) : (
                                <button className="btn upload_btn" onClick={handleSave} disabled={loading}>
                                    {loading ? (
                                        <CircularProgress style={{ marginRight: 10, color: "#fff", width: 12, height: 12 }} />
                                    ) : (
                                        <>
                                            Upload
                                        </>
                                    )}
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddPhotoModal;
